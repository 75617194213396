@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;600&display=swap');

body, html {
    height: 100%;
    margin: 0;
}

#page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: F5F5F5;
}

#content-wrap {
    flex: 1; /* This makes the content area grow to fill the remaining space */
}

img {
    border-radius: 2vw;
    padding: 4vh;
    width: 50%;
}

p {
    font-family: 'Teko', sans-serif;
    font-weight: 300;
    font-size: 2vw;
    padding: 6vh;
}

.infoContainer {
    display: flex;
    align-items: center;
}

.slogan-section {
    position: relative;
    text-align: left;
}

.slogan-img {
    border-radius: 0vw !important;
    padding: 0vh !important;
    width: 100%;
}

.slogan-text {
    position: absolute;
    padding: 2vh;
    top: 1vw;
    left: 3vw;
    width: 70%;
}

h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 7vw !important;
    color: whitesmoke !important;
    font-family: 'Teko', sans-serif;
}

h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 7vw !important;
    color: rgb(27, 35, 117) !important;
    font-family: 'Teko', sans-serif;
}


:root {
    --cui-tertiary-bg-rgb: 27, 35, 117 !important;
    --cui-emphasis-color-rgb: 255, 255, 255 !important;
}
.navbar-toggler {
    background-color: #FFFF !important;
}

.footer{
    --cui-footer-bg: rgb(27, 35, 117) !important;
    --cui-footer-color: rgb(255, 255, 255) !important;
}

@media screen and (max-width: 550px) {
    p {
        font-size: 3vw;
        padding: 2vh;
    }

    img {
        padding: 2vh;
    }
}

@media screen and (min-width: 2700px) {
    .homeContent {
        align-items: center;
        padding-left: 10vw;
        padding-right: 10vw;
    }
}
